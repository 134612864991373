{
  "common": {
    "help": "帮助",
    "sure": "确认",
    "cancel": "取消",
    "skip": "跳过",
    "submit": "提交",
    "agree": "同意",
    "refresh": "刷新",
    "newki": "新奇站",
    "searchGoods": "搜索商品",
    "addCar": "加入购物车",
    "buyNow": "立即购买",
    "vip": "品友",
    "newVip": "新品友",
    "apply": "开通",
    "check": "查看",
    "default": "默认",
    "save": "保存",
    "save&use": "保存并使用",
    "delete": "删除",
    "confirm": "请确认",
    "finish": "完成",
    "backHome": "回到首页",
    "checkOrder": "查看订单",
    "edit": "编辑",
    "remove": "移除",
    "pullRefresh": "下拉即可刷新内容~",
    "pullLoosing": "松开即可刷新內容~",
    "loading": "加载中……",
    "noMore": "沒有更多啦~",
    "copy": "复制",
    "copySuccess": "已复制",
    "copyError": "复制失败",
    "rule": "规则",
    "changeSuccess": "修改成功",
    "select": "选择",
    "recommend": "为您推荐",
    "close": "关闭",
    "wechatAuthError": "微信授权失败",
    "vipPrice": "品友价",
    "login": "登录",
    "total": "总计",
    "remark": "备注",
    "siteUnavailable": "当前站点不可用",
    "retryLater": "出错了，请稍后重试~",
    "siteSelectTitle": "选择地区"
  },
  "login": {
    "welcome": "欢迎登入新奇站",
    "inputPhoneNumber": "请输入手机号",
    "inputRightPhoneNumber": "请输入正确的手机号",
    "inputSmsCode": "请输入6位手机验证码",
    "getSmsCode": "获取验证码",
    "seconds": "秒",
    "login": "立即登入/注册",
    "loginNow": "立即登入",
    "loginPwd": "密码登入",
    "loginSms": "验证码登入/注册",
    "loginTip": "未注册时验证码登入后将自动创建新奇站帐号",
    "ServiceAgreement": "《服务协议》",
    "PrivacyAgreement": "《隐私协议》",
    "agreementTip": "请阅读并勾选同意",
    "loginAgreement": "登入即代表同意",
    "logging": "登入中...",
    "smsError": "验证码错误，请重新输入~",
    "findPwd": "找回密码",
    "graphicCode": "请输入图形验证码",
    "graphicRightCode": "请输入正确的图形验证码",
    "setPwd": "设置密码",
    "inputPwd": "请输入密码",
    "setPwdAgain": "请再次确认密码",
    "setPwdError": "两次密码不一致，请重新输入",
    "pwdVerify": "请输入8-20位密码，含字母、数字",
    "pwdError": "密码错误，请重新输入~",
    "smsExpire": "验证码已失效，请重新获取",
    "chooseAreaCode": "请选择手机区号",
    "chooseLanguage": "请选择语言",
    "chooseSite": "请选择要访问的站点",
    "finishPicVerify": "向右拖动完成拼图",
    "SecurityVerification": "安全验证",
    "error:": "登录过期，请重新登录",
    "loginFloat": "登入开启新奇购物",
    "loginAgain": "修改成功，请重新登录！",
    "thirdLogin": "第三方登入",
    "bind": "绑定手机",
    "bind-tip": "请绑定您当前使用的手机，以便新奇站能提供更优质的品友服务。",
    "cancelRegister": "取消注册"
  },
  "site": {
    "selectSite": "请选择要访问的站点",
    "selectLanguage": "请选择语言",
    "selectSiteFirst": "请先选择要访问的站点！",
    "areaCodeError": "该站点未设置区号代码，请联系管理员"
  },
  "search": {
    "search": "搜索",
    "normal": "综合",
    "count": "销量",
    "new": "上新",
    "category": "品类",
    "history": "搜索历史",
    "hot": "热门搜索",
    "urlError": "跳转地址不符合URL规则",
    "goodsPlaceholder": "请输入要搜索的商品名称",
    "goodsOrderPlaceholder": "请输入要搜索的订单商品",
    "historyEmpty": "浏览记录为空~",
    "orderEmpty": "您还没有下过单哦~",
    "collectEmpty": "您的收藏为空",
    "cartEmpty": "购物车为空",
    "historySearchEmpty": "没找到合适的商品~",
    "orderSearchEmpty": "你还未买过当前商品~"
  },
  "goods": {
    "spec": "规格",
    "change": "换购",
    "discountInfo": "共{num}件，优惠价：",
    "pkg": "精选套装",
    "book": "立即预约",
    "booked": "已预约",
    "offState": "商品已下架",
    "offStorage": "地区无货,请切换地址",
    "buyCount": "购买数量",
    "limitCount": "限购{num}件",
    "orderWay": {
      "online": "送货上门",
      "onlineDesc": "支持物流送货上门，下单时填写收货信息即可。",
      "take": "顺丰自提",
      "takeDesc": "1、支持顺丰自提点取货。\n2、下单时填写自提点信息即可。\n3、由于自提点有大小限制，是否可以使用，需在下单时计算商品总体验后，才决定能否使用。",
      "offLine": "门市自取",
      "offLineDesc": "1、支持到门市取货。\n2、下单时选择门市自取即可。\n3、门市："
    },
    "weight": "重量：{weight}kg",
    "volume": "体积：{volume}cm³",
    "bindBuy": "请先添加数量~",
    "error": "改商品不存在~"
  },
  "promotion": {
    "exclusivePrice": "专属价",
    "price": "当前购买价",
    "originPrice": "原价",
    "vipTip": "开通品友即可享受",
    "promotionTip": "限时优惠",
    "pkgName": "推荐套装",
    "start": "距离开始",
    "end": "距离结束",
    "reduce": "立减",
    "discount": "优惠",
    "bind": "一起买，更优惠",
    "countChange": "满{num}件换购",
    "countChangeDetail": "购买本商品<span class='discount-text'>满{num}件</span>，可<span class='discount-text'>换购{count}件<span>",
    "changeDetail": "购买本商品，可<span class='discount-text'>换购{count}件<span>",
    "countGift": "满{num}件赠券",
    "countGiftDetail": "购买本商品<span class='discount-text'>满{num}件</span>，支付订单后系统自动发放以下优惠券",
    "priceGift": "满{num}赠券",
    "priceGiftDetail": "购买本商品<span class='discount-text'>满{num}</span>，支付订单后系统自动发放以下优惠券",
    "coupon": "赠券",
    "couponDetail": "购买本商品，支付订单后系统自动发放以下优惠券",
    "priceChange": "满{num}换购",
    "priceChangeDetail": "订单<span class='discount-text'>满{num}</span>，可<span class='discount-text'>换购{count}件</span>",
    "change": "去换购",
    "changeTitle": "换购",
    "allDiscount": "满${num}享{count}折",
    "allDiscountDetail": "购买<span class='discount-text'>满{num}</span>，可享<span class='discount-text'>{count}折</span>",
    "allReduce": "满${num}减{count}",
    "allReduceDetail": "购买<span class='discount-text'>满{num}</span>，可立减<span class='discount-text'>{count}</span>",
    "bindQtyDiscount": "满{num}件{count}折",
    "bindQtyDiscountDetail": "购买本商品<span class='discount-text'>满{num}件</span>，每件商品可享<span class='discount-text'>{count}折</span>",
    "bindQtyReduce": "满{num}件每件减{count}",
    "bindQtyReduceDetail": "购买本商品<span class='discount-text'>满{num}件</span>，每件商品可立减<span class='discount-text'>{count}</span>",
    "bindPriceDiscount": "滿{num}享{count}折",
    "bindPriceDiscountDetail": "购买本商品<span class='discount-text'>滿{num}</span>，可享<span class='discount-text'>{count}折</span>",
    "bindPriceReduce": "满{num}件减{count}",
    "bindPriceReduceDetail": "购买本商品<span class='discount-text'>滿{num}</span>，可立减<span class='discount-text'>{count}</span>",
    "singleDiscount": "每件享{count}折",
    "singleDiscountDetail": "购买本商品每件可享<span class='discount-text'>{count}折</span>",
    "singleReduce": "每件减{count}",
    "singleReduceDetail": "购买本商品每件立减<span class='discount-text'>{count}</span>",
    "changeSelect": "超值换购-可选",
    "order": {
      "amount": "商品金额",
      "vipDiscount": "品友折扣",
      "vipBenefit": "品友优惠",
      "freightDiscount": "运费优惠",
      "discount": "促销优惠",
      "vipCoupon": "品友券",
      "coupon": "优惠券",
      "couponText": "券",
      "freightCoupon": "运费券",
      "reduce": "随机立减",
      "reduceText": "减",
      "buyAmount": "应付款",
      "freight": "运",
      "changeDiscount": "惠",
      "original": "原订单抵扣",
      "changeText": "换"
    },
    "moreDiscount": "获取更优价",
    "morePromotion": "更多优惠",
    "changeGoods": "优惠换购品",
    "giftError": "已超过可选换购品最大数量，请重新选择~",
    "changeGoodsNotSelectYet": "您有优惠换购品未选择~"
  },
  "coupon": {
    "discount": "<span class='coupon-discount'>{num}</span>折",
    "reduce": "减<span class='coupon-discount'>{num}</span>",
    "special": "指定商品可用",
    "all": "全场通用",
    "vip": "专属券",
    "freightName": "运费券",
    "limit": "满{num}可用",
    "freight": "用于订单运费抵扣",
    "use": "立即使用",
    "receive": "立即领取",
    "gift": "赠{num}张",
    "allDate": "永久有效",
    "dynamicDate": "{num}天后过期",
    "noCoupon": "无可用券",
    "hasCoupon": "有{num}张可用，请选择",
    "modalTitle": "新奇优惠券",
    "able": "可用优惠券",
    "disable": "不适用优惠券",
    "coupon": "优惠券",
    "exchange": "立即兑换",
    "noUse": "暂无可用",
    "member": "品友券",
    "exchangeCoupon": "品礼券",
    "exchangeCouponA": "品礼兑换券"
  },
  "address": {
    "distribution": "送至",
    "listTitle": "地址管理",
    "add": "新增地址",
    "edit": "编辑地址",
    "receiver": "收货人",
    "receiverValid": "请输入收货人信息",
    "phone": "手机号码",
    "phoneValid": "请输入手机号码",
    "areInfo": "所在地区",
    "areInfoValid": "请选择所在地区",
    "address": "详细地址",
    "addressValid": "请输入详细地址",
    "setDefault": "设为默认",
    "delete": "确认删除该地址？",
    "deleteSuccess": "刪除成功",
    "saveSuccess": "添加成功",
    "errorUrl": "无效的地址",
    "exceed": "地址超出配送范围，请修改地址!"
  },
  "number": {
    "thousand": "千",
    "tenThousand": "万",
    "hundredMillion": "亿"
  },
  "track": {
    "track": "快递",
    "list": "快递列表",
    "trackInfo": "快递信息",
    "listEmpty": "暫無快递信息~",
    "order": "已下单",
    "orderMeta": "商品已下单",
    "stock": "商品备货中",
    "stockMeta": "商品备货出库中",
    "package": "已发货",
    "packageMeta": "商品已经打包，等待揽收",
    "done": "已完成",
    "doneMeta": "商品已经签收，欢迎下次观临",
    "transit": "运输中",
    "pickup": "派送中",
    "delivered": "已签收",
    "waitTake": "待交收",
    "takeDone": "已交收"
  },
  "order": {
    "order": "订单",
    "list": "订单列表",
    "tabs": {
      "all": "全部",
      "payment": "待支付",
      "progress": "待处理",
      "done": "待交收",
      "sharing": "待分享"
    },
    "status": {
      "status": "订单状态",
      "cancel": "已取消",
      "payment": "待支付",
      "paySure": "支付确认中",
      "payError": "支付异常",
      "payNotAll": "部分付款",
      "handle": "门店备货中",
      "storageHandle": "仓库备货中",
      "waitTake": "待取货",
      "waitReceive": "待收货",
      "finish": "已完成"
    },
    "payError": "检测到支付异常，订单未完成支付~",
    "paySure": "您的支付信息正在确认中，请耐心等待~",
    "payNotAll": "订单仅部份付款，请及时支付尾款~",
    "btnGroup": {
      "pay": "支付",
      "payAgain": "重新支付",
      "payResidue": "支付尾款",
      "payContinue": "继续支付",
      "addCart": "加入购物车",
      "checkTrack": "查看物流",
      "sure": "确认收货",
      "chat": "联系客服",
      "share": "分享体验",
      "shareContinue": "继续分享",
      "afterSale": "申请售后",
      "cancelOrder": "取消订单",
      "afterSaleSearch": "售后查询",
      "sureDialog": "确认已经收到订单商品？",
      "sureDoAfterSale": "确认申请售后？",
      "instruction": "使用说明",
      "afterSaleQty": "已退/换<span>{num}</span>件"
    },
    "cancel": {
      "title": "取消原因",
      "sureAgain": "请再次确认是否取消当前订单？"
    },
    "error": "订单不存在~",
    "noSameUser": "当前登入的账户与交收信息不一致"
  },
  "orderDetail": {
    "statusDesc": {
      "cancel": "您的订单已取消\n如有支付款项将于1~3个工作日退回\n欢迎下次光临~",
      "finish": "您的订单已完成，欢迎下次光临~",
      "handle": "您的订单正在备货中\n请耐心等待~",
      "payNotAll": "您的转账金额少于订单应付金额！\n请及时支付尾款~",
      "payError": "您的转账信息有误，请重新支付~",
      "paySureOnline": "您的支付信息正在确认中\n请耐心等待~",
      "paySureOffline": "您的转账信息核对中，\n预计1~3个工作日完成~\n如上传凭证信息有误 ，请及时修改~",
      "payment": "超时后订单将自动取消\n已取消订单无法恢复，请及时支付~"
    },
    "delivery": {
      "onLine": "送货上门",
      "offLine": "门市自取",
      "takeWay": "自提点取货"
    },
    "order": {
      "no": "订单号",
      "createTime": "下单时间",
      "payTime": "支付时间",
      "payWay": "支付方式",
      "paySn": "支付单号",
      "amount": "商品金额",
      "freight": "运费",
      "paid": "实付款",
      "shouldPaid": "应付款",
      "codAmount": "货到付款金额"
    },
    "storeOrder": {
      "no": "订单号",
      "sourceBillNo": "来源订单号",
      "createTime": "购买时间",
      "standardAmount": "商品金额",
      "discountAmount": "优惠金额",
      "payAmount": "实付金额"
    },
    "showMore": "查看更多",
    "payProof": "支付凭证",
    "morePkg": "订单分多个包裹发出",
    "takeCode": "取货码",
    "takeTip": "请于 {time} 前往指定门市取货~",
    "remark": "订单留言",
    "vip": {
      "name": "新奇站品友",
      "disable": "已失效",
      "able": "待生效",
      "time": "有效期至：{time}"
    },
    "vipName": "品友",
    "integral": "获得积分"
  },
  "buy": {
    "title": "确认订单",
    "similar": "相似订单未支付，并且已使用优惠券",
    "similarJump": "操作",
    "addAddress": "请添加地址！",
    "selectAddress": "地址列表",
    "selectShop": "选择门市",
    "shop": "门市",
    "shopEmpty": "请选择取货门店！",
    "remark": "订单留言，最多100字",
    "submit": "提交订单",
    "total": "合计",
    "discount": "已优惠",
    "explain": "价格说明",
    "manualBillTips": {
      "tips": "溫馨提示",
      "noSameUser": "当前登入的账户与交收信息不一致 \n 是否切换为登入账户",
      "confirm": "确认",
      "cancel": "取消",
      "errorContact": "因输入错误次数较多，该订单已锁定 \n 请通过下单平台联系客服 \n 或添加客服WhatsApp賬戶",
      "contact": "复制 WhatsApp",
      "copySuccess": "复制成功",
      "backHome": "回首页",
      "billError": "订单失效了，请联系小蓝帮你重新下单"
    },
    "site": {
      "infoTip": "信息提示",
      "infoDesc": "当前登入站点与订单提供站点信息不一致，请确认是否继续下单或切换站点~",
      "goon": "继续下单",
      "change": "切换到{name}",
      "chat": "联系客服",
      "languageError": "该站点未配置语言，切换失败"
    },
    "vip": {
      "able": "本单开通品友已省",
      "disable": "开通品友，本单预计可省",
      "check": "品友专属权益待生效~"
    },
    "deliveryTypeError": "获取交收方式有误",
    "addressIdError": "请填写交收信息",
    "exceedError": "地址超出配送范围，请填写地址",
    "noLoginSubimitErrorTips": "请先填写交收资料"
  },
  "sendWay": {
    "1": "送货上门",
    "2": "门市自取",
    "3": "自提点取货",
    "maskBtn": "点击校验交收资料",
    "loginBtn": "登入查看详情"
  },
  "pay": {
    "title": "收银台",
    "vipPrice": "包含品友购买  {price}/年",
    "scan": "扫码支付",
    "app": "请使用APP扫描支付",
    "pay": "支付",
    "paying": "支付中",
    "cancel": "订单已取消",
    "promotionTime": "促销优惠，剩余支付时间",
    "payTime": "剩余支付时间",
    "levelMessage": "家D好品仅差一步！\n确认不继续吗？",
    "waitPay": "稍后支付",
    "payGoing": "继续支付",
    "payConfirm": "1、已支付请点击【我已支付】，并耐心等待支付结果，请勿重复支付！\n2、如需更换支付方式，请点击【去支付】",
    "goPay": "去支付",
    "payDone": "我已支付",
    "success": {
      "finish": "支付完成",
      "search": "支付中...",
      "sendCoupon": "新奇小蓝给您送券啦~",
      "sendCouponTip": "订单确认收货后系统自动确认",
      "searchTip": "1、由于网络原因，当前支付结果未能及时获取\n2、如确认已经支付，请耐心等待或联系客服\n3、如您未支付请点击重新支付",
      "backApp": "返回「新奇站」APP",
      "downloadApp": "下载「新奇站」APP",
      "backTip": "如返回失败，请手动打开新奇站APP",
      "searchAppTip": "1、由于网络原因，当前支付结果未能及时获取\n2、请返回「新奇站」APP等待支付结果"
    },
    "amount": "金额",
    "error": "支付异常，请选择其他支付方式！"
  },
  "cart": {
    "cart": "购物车",
    "invalid": "失效商品",
    "otherSite": "其它站点商品",
    "selectAll": "全选",
    "change": "换购",
    "changeText": "优惠换购品，请选择",
    "amount": "合计",
    "discount": "已优惠",
    "priceExplain": "价格说明",
    "buy": "结算",
    "collect": "收藏",
    "addCollect": "加入收藏",
    "removeGoods": "移除商品",
    "gift": "换购",
    "empty": "购物车没有商品哦~",
    "addSuccess": "添加成功！",
    "confirmDelete": "确定删除这些商品吗？",
    "limit": "限购：{num}",
    "inventory": "库存：{num}",
    "cancelCollect": "取消收藏",
    "sureRemove": "确认移除该列表商品吗？",
    "sureCancelCollect": "确认取消收藏选中商品吗？",
    "success": "加入购物车成功"
  },
  "nav": {
    "home": "首页",
    "category": "品类",
    "newki": "家品历",
    "shop": "购物",
    "mine": "我的"
  },
  "applyVip": {
    "apply": "开通品友更优惠",
    "discount": "本单已省",
    "forecast": "本单预计可省",
    "desc": "全站会员价",
    "desc1": "(非会员商品除外)",
    "agree": "本人已阅读并同意",
    "agreement": "《新奇站增值会员用户协议》",
    "couponNum": "品友券X{num}",
    "goodsExchangeNum": "品礼券X{num}",
    "freightNum": "运费券X{num}",
    "exchangeNum": "品礼券X{num}",
    "memberNum": "品友券X{num}",
    "monthNum": "每月{num}张",
    "special": "会员专属",
    "noLimit": "无门槛",
    "hasLimit": "指定商品可用",
    "activity": "VIP活动",
    "gift": "奇趣礼品",
    "more": "更多惊喜",
    "noDiscount": "无专属优惠"
  },
  "transfer": {
    "tip1": "1、转账成功后，请点击页面下方【上传转账凭证】按钮，上传转账成功的截图资料。",
    "tip2": "2、Newki将在一个工作日进行资料核对，核对完成后为你提供商品的寄送服务。",
    "transferInfo": "转账信息",
    "hide": "收起",
    "show": "查看",
    "payImage": "提交成功过数截图",
    "uploadPayImage": "上传转账凭证",
    "changePayWay": "修改支付方式",
    "chat": "联系客服",
    "payTeaching": "付款教程",
    "submit": "确认提交",
    "outTradeNoTip": "请複制以下内容至转账页面备注处。"
  },
  "fileUpload": {
    "sizeError": "请上传小于{size}M的文件",
    "typeError": "请上传{type}类型的文件",
    "numError": "请上传少于{num}个的文件",
    "uploading": "上传中...",
    "failed": "上传失败"
  },
  "setting": {
    "title": "设置",
    "info": "个人信息",
    "address": "收货地址管理",
    "pwd": "修改密码",
    "newki": "关于新奇站",
    "registerAgreement": "用户注册协议",
    "agreement": "隐私政策",
    "logout": "登出"
  },
  "update": {
    "pwd": {
      "title": "修改密码",
      "updateBySms": "通过手机验证码修改",
      "updateByPwd": "通过旧密码修改",
      "submit": "确认修改",
      "cancel": "取消修改",
      "oldPwd": "请输入当前登入密码"
    },
    "phone": "修改手机号码",
    "nickname": "修改昵称",
    "nicknameLabel": "昵称",
    "nicknamePlaceholder": "请输入昵称",
    "email": "修改邮箱",
    "emailLabel": "邮箱",
    "emailPlaceholder": "請輸入您的郵箱地址",
    "emailErrorTips": "請輸入正確的郵箱地址"
  },
  "history": {
    "title": "浏览记录",
    "empty": "暂无浏览记录",
    "addCart": "加入购物车",
    "addCollect": "加入收藏"
  },
  "integral": {
    "title": "我的积分",
    "canUse": "可用积分",
    "total": "总积分",
    "frozen": "冻结积分",
    "frozenExplain": "正常交易后获得的积分，会有7天的冻结期，之后会变更为可用积分进行使用",
    "empty": "暂无积分使用明细~",
    "normal":"正常交易",
    "marketingActivity": "营销活动",
    "integralActivity": "积分活动",
    "integralOverDue": "积分过期",
    "integralOffset": "积分抵现",
    "cancelOffset": "取消抵现",
    "manualAdjustment": "人工调整",
    "integralClear": "积分清零",
    "integralExchange": "积分兑换",
    "integralLock": "积分锁定",
    "integralUnlock": "积分释放"
  },
  "coupons": {
    "title": "优惠券",
    "notUse": "待使用",
    "used": "已使用",
    "failed": "已过期",
    "empty": "该分类没有优惠券~"
  },
  "my": {
    "notLogin": "未登入",
    "registerDay": "新奇体验已<span class='blue-text'>{day}</span>天",
    "all": "全部",
    "more": "更多",
    "order": {
      "title": "我的订单",
      "titleTabs": {
        "site": "官网订单",
        "shop": "门店订单"
      },
      "notPay": "待付款",
      "deal": "待处理",
      "makeSure": "待交收",
      "waitShare": "待分享",
      "afterSale": "售後"
    },
    "history": "浏览记录",
    "share": "分享",
    "myShare": "我的分享",
    "myCollect": "我的收藏",
    "view": "近期浏览",
    "integral": "积分",
    "coupon": "优惠券",
    "collectEmpty": "你还未收藏任何内容喔,<br/>快<span class='blue-text'>去逛逛</span>吧~",
    "shareEmpty": "你还未发佈任何内容喔,<br/>快<span class='blue-text'>去分享</span>吧~",
    "viewEmpty": "你还未浏览过新奇商品喔,<br/>快<span class='blue-text'>去逛逛</span>吧~"
  },
  "vipPackage": {
    "discount": "累积已省",
    "integral": "我的积分",
    "doubleIntegral": "双倍积分",
    "integralDiscount": "积分优惠",
    "present": "奇趣礼品",
    "more": "更多惊喜",
    "gift": "品友礼物",
    "active": "专属活动",
    "month": "每月",
    "week": "每周",
    "year": "每年",
    "day": "每日",
    "coupon": "品友券",
    "freight": "运费券",
    "goodsExchange": "品礼券",
    "couponDes": "专属优惠券",
    "freightDes": "运费抵扣券",
    "goodsExchangeDes": "品礼兑换券",
    "vipPrice": "全站会员价",
    "send": "赠送",
    "unit": "张",
    "onlyVip": "仅会员商品",
    "discountReduce": "低至8.5折",
    "noLimit": "无门槛",
    "exclusive": "专属",
    "member": "品友券"
  },
  "error": {
    "pageNotFount": "当前页面不存在",
    "pageJump": "页面将在",
    "pageJump1": "秒后自动跳转"
  },
  "share": {
    "title": "体验分享",
    "share": "分享体验",
    "shareTip": "欢迎写下您的新奇体验…",
    "shareGoods": "体验过的新奇商品",
    "select": "请选择",
    "myShare": "我的分享",
    "myShareEmpty": "暂时没有分享哦~",
    "myLike": "我的点赞",
    "myLikeEmpty": "暂时没有点赞哦~",
    "textEmpty": "请填写分享的内容~",
    "imageEmpty": "请上传分享的图片~"
  },
  "userInfo": {
    "title": "个人信息",
    "phone": "手机号码",
    "nickname": "昵称",
    "email": "邮箱"
  },
  "emailPopup": {
    "title": "请填写邮箱用于账单服务",
    "agreement": "《支付服务说明》",
    "errorTips": "请输入正确的邮箱地址"
  },
  "phonePopup": {
    "title": "请填写下单手机号",
    "errorTips": "手机号码错误，请重试",
    "diffTips": "交收手机号码错误，请重试"
  },
  "sharePopup": {
    "title": "立即分享给好友",
    "link": "复制链接"
  },
  "collect": {
    "success": "加入收藏成功",
    "deleSuccess": "取消收藏成功"
  },
  "newki": {
    "entry": "进入推荐内容",
    "month": "月",
    "monthly": "每月家D新品味?!",
    "monthlyYet": "今月你家佐未?!",
    "monthlyAdd": "家D",
    "fit": "宜"
  },
  "afterSale": {
    "status": "状态",
    "list": "售后列表",
    "history": "售后记录",
    "detail": "售后服务详情",
    "detailApply": "售后服务申請"
  },
  "article": {
    "title": "文章详情",
    "error": "未传入正确的文章ID或使用场景！",
    "empty": "该文章暂无内容~"
  },
  "activity": {
    "fail": "活动已过期",
    "end": "活动已结束",
    "integral": "当前积分",
    "num": "可换次数",
    "change": "立即兑换",
    "join": "立即参与",
    "record": "兑换记录",
    "recordEmpty": "暂无兑换记录~",
    "invite": "邀请记录",
    "inviteEmpty": "暂无邀请记录，快分享给好友吧~",
    "rule": "活动规则",
    "memberDay": "可体验会员权益{num}个月",
    "price": "价值",
    "view": "去查看",
    "congratulations": "恭喜用户",
    "receive": "获得",
    "joined": "您已参与过该活动~"
  },
  "chat": {
    "sendOrder": "发送订单",
    "goods": "发送商品",
    "searchOrder": "查询订单",
    "title": "新奇站正在为您服务",
    "sendTip": "说点什么",
    "noMoreMsg": "--没有更多消息--",
    "loading": "加载中...",
    "error": "消息获取失败！",
    "sendEmpty": "发送消息不能为空~",
    "addAsk": "添加咨询",
    "loginAsk": "登录咨询",
    "newkiAsk": "新奇小蓝咨询",
    "navTitle": "很高兴为您服务，每月家D新品未!?"
  },
  "cabinet": {
    "title": "取货信息",
    "type": {
      "sfStation": {
        "name": "顺丰站",
        "tips": "顺丰服务站，适用于各类合规货物的收发"
      },
      "smartLocker": {
        "name": "智能柜",
        "tips": "适用于较小货物的存放，凭短信码自助取件"
      },
      "servicePoint": {
        "name": "营业点",
        "tips": "顺丰合作便利店，适用于各类货物的收件"
      }
    },

    "userName": "收货人",
    "inputUserName": "请输入收货人信息",
    "phoneNum": "手机号码",
    "inputPhoneNum": "请输入正确的手机号",
    "addPickUpPointer": "新增取货点",
    "addTips": "请新增取货点",
    "selectAddressDetail": "请选择集装箱地址",

    "selectTitle": "选择取货地址",
    "inputAddressOrCode": "请输入地址或者编码",
    "pickupPointerType": "自提点类型",
    "selectPickPointer": "请选择自提点类型",
    "pickupPointerArea": "所在地区",
    "selectArea": "请选择所在地区",
    "detailAddress": "详细地址",

    "searchTips": "请输入地址或编码",
    "unavailable": "商品超出格口限制，不可用",
    "invalid": "该地址已失效，请重选",

    "search": "搜索",
    "next": "下一步",
    "save": "保存",
    "saveTips": "请填写取货信息",
    "saveAndUse": "保存並使用",
    "emptyTips": "没有找到相关智能柜<br/>试试别的关键词",
    "emptyAreaTips": "该区域下无数据，请重新选择~"
  },
  "render": {
    "title": "内容组件多语言",
    "button": {
      "buyNow": "立即开通 <span class='buy-now'>{price}</span> /年",
      "tips": "限时优惠",
      "agree": "本人已阅读并同意",
      "articleTitle": "《新奇站增值会员用户协议》",
      "chat": "联繫客服",
      "goPay": "继续支付"
    },
    "promotion": {
      "buyVipNow": "支付到账确认中<br/>如上传凭证信息有误 ，请及时修改~",
      "partialPayment": "您的转账金额少于订单应付金额！<br/>请及时支付尾款~",
      "billError": "您的转账信息有误，请重新支付~",
      "inEffect": "品友权益生效中~",
      "exist": "您有订单加购了品友，完成支付后可享有权益"
    }
  }
}
